body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-form{
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 64px;
    width: 320px;
    height: 320px;
}
.login-form__title-content{
    margin-left: 16px;
    margin-top: 8px;
}
.login-form__button{
    float: right;
}
.login-form__footer{
    margin-right: 16px;
    margin-top: 16px;
}
.login-form__field{
    width: 100%;
}

.failed-login-counter{
    padding-bottom: 32px;
    padding-left: 32px;
}

