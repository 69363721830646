.login-form{
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 64px;
    width: 320px;
    height: 320px;
}
.login-form__title-content{
    margin-left: 16px;
    margin-top: 8px;
}
.login-form__button{
    float: right;
}
.login-form__footer{
    margin-right: 16px;
    margin-top: 16px;
}
.login-form__field{
    width: 100%;
}

.failed-login-counter{
    padding-bottom: 32px;
    padding-left: 32px;
}